/* tslint:disable */
/* eslint-disable */
/**
 * aquaway-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Registration } from '../model';
/**
 * RegistrationApi - axios parameter creator
 * @export
 */
export const RegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get registrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRegistrations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update registration
         * @param {number} id 
         * @param {Registration} [registration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegistration: async (id: number, registration?: Registration, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateRegistration.');
            }
            const localVarPath = `/registration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof registration !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(registration !== undefined ? registration : {}) : (registration || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationApi - functional programming interface
 * @export
 */
export const RegistrationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get registrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRegistrations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Registration>>> {
            const localVarAxiosArgs = await RegistrationApiAxiosParamCreator(configuration).getAllRegistrations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update registration
         * @param {number} id 
         * @param {Registration} [registration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegistration(id: number, registration?: Registration, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await RegistrationApiAxiosParamCreator(configuration).updateRegistration(id, registration, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RegistrationApi - factory interface
 * @export
 */
export const RegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get registrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRegistrations(options?: any): AxiosPromise<Array<Registration>> {
            return RegistrationApiFp(configuration).getAllRegistrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update registration
         * @param {number} id 
         * @param {Registration} [registration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegistration(id: number, registration?: Registration, options?: any): AxiosPromise<Registration> {
            return RegistrationApiFp(configuration).updateRegistration(id, registration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationApi - object-oriented interface
 * @export
 * @class RegistrationApi
 * @extends {BaseAPI}
 */
export class RegistrationApi extends BaseAPI {
    /**
     * 
     * @summary Get registrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public getAllRegistrations(options?: any) {
        return RegistrationApiFp(this.configuration).getAllRegistrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update registration
     * @param {number} id 
     * @param {Registration} [registration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public updateRegistration(id: number, registration?: Registration, options?: any) {
        return RegistrationApiFp(this.configuration).updateRegistration(id, registration, options).then((request) => request(this.axios, this.basePath));
    }
}
