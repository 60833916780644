import eventBus from "@/service/eventbus.service";

export const NOTIFICATION = "notification";
export const NOTIFICATION_HIDE = "notification.hide";
export const NOTIFICATION_HIDE_ALL = "notification.hide.all";
export const NOTIFICATION_HIDE_ERRORS = "notification.hide.errors";
export const NOTIFICATION_UPDATE = "notification.update";

export enum NotificationType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export type NotificationMessageOptions = {
  params?: object;
  top?: boolean;
  dismissible?: boolean;
  timeout?: number;
}

export class NotificationMessage {
  id: number;
  type: NotificationType;
  message: string;
  options?: NotificationMessageOptions;

  constructor(id: number, type: NotificationType, message: string, options?: NotificationMessageOptions) {
    this.id = id;
    this.type = type;
    this.message = message;
    this.options = options;
  }
}

class NotificationService {
  private newMessageId(): number {
    return (Date.now() * 100) + Math.round(Math.random() * 100);
  }

  showNotification(type: NotificationType, message: string, options?: NotificationMessageOptions): number {
    const id = this.newMessageId();
    eventBus.$emit(NOTIFICATION, new NotificationMessage(id, type, message, options));

    return id;
  }

  updateNotification(id: number, type: NotificationType, message: string, options?: NotificationMessageOptions) {
    eventBus.$emit(NOTIFICATION_UPDATE, new NotificationMessage(id, type, message, options));
  }

  hideNotification(id: number) {
    eventBus.$emit(NOTIFICATION_HIDE, id);
  }

  hideNotifications(): void {
    eventBus.$emit(NOTIFICATION_HIDE_ALL);
  }

  hideErrorNotifications(): void {
    eventBus.$emit(NOTIFICATION_HIDE_ERRORS);
  }
}

export default new NotificationService();
