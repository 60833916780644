








import {Component, Vue} from "vue-property-decorator";

@Component
export default class Calendar extends Vue {
  private content = "";

  mounted() {
    //
  }
}
