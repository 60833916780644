import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './plugins/vue-i18n';
import moment from "moment";
Vue.config.productionTip = false



Vue.use(VueAxios, axios)
Vue.prototype.moment = moment

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
