/* tslint:disable */
/* eslint-disable */
/**
 * aquaway-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { WorkOutPlan } from '../model';
/**
 * WorkoutPlanApi - axios parameter creator
 * @export
 */
export const WorkoutPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary confirm workout plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWorkOutPlan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling confirmWorkOutPlan.');
            }
            const localVarPath = `/workout-plan/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new workout plan
         * @param {WorkOutPlan} [workOutPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkoutPlan: async (workOutPlan?: WorkOutPlan, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workout-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof workOutPlan !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workOutPlan !== undefined ? workOutPlan : {}) : (workOutPlan || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete workout plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkOutPlan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteWorkOutPlan.');
            }
            const localVarPath = `/workout-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workout plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserEditableWorkOutPlans: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workout-plan/trainer/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workout plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserWorkOutPlans: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workout-plan/trainer/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workout plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkOutPlans: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workout-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get workout plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkOutPlan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getWorkOutPlan.');
            }
            const localVarPath = `/workout-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update workout plan
         * @param {number} id 
         * @param {WorkOutPlan} [workOutPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkOutPlan: async (id: number, workOutPlan?: WorkOutPlan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateWorkOutPlan.');
            }
            const localVarPath = `/workout-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof workOutPlan !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workOutPlan !== undefined ? workOutPlan : {}) : (workOutPlan || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary confirm workout plan
         * @param {number} id 
         * @param {number} workoutId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkOutStatus: async (id: number, workoutId: number, status: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateWorkOutStatus.');
            }
            // verify required parameter 'workoutId' is not null or undefined
            if (workoutId === null || workoutId === undefined) {
                throw new RequiredError('workoutId','Required parameter workoutId was null or undefined when calling updateWorkOutStatus.');
            }
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling updateWorkOutStatus.');
            }
            const localVarPath = `/workout-plan/{id}/workout/{workoutId}/update-status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"workoutId"}}`, encodeURIComponent(String(workoutId)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkoutPlanApi - functional programming interface
 * @export
 */
export const WorkoutPlanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary confirm workout plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmWorkOutPlan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOutPlan>> {
            const localVarAxiosArgs = await WorkoutPlanApiAxiosParamCreator(configuration).confirmWorkOutPlan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create new workout plan
         * @param {WorkOutPlan} [workOutPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkoutPlan(workOutPlan?: WorkOutPlan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOutPlan>> {
            const localVarAxiosArgs = await WorkoutPlanApiAxiosParamCreator(configuration).createWorkoutPlan(workOutPlan, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete workout plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkOutPlan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WorkoutPlanApiAxiosParamCreator(configuration).deleteWorkOutPlan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workout plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUserEditableWorkOutPlans(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkOutPlan>>> {
            const localVarAxiosArgs = await WorkoutPlanApiAxiosParamCreator(configuration).getAllUserEditableWorkOutPlans(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workout plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUserWorkOutPlans(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkOutPlan>>> {
            const localVarAxiosArgs = await WorkoutPlanApiAxiosParamCreator(configuration).getAllUserWorkOutPlans(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workout plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWorkOutPlans(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkOutPlan>>> {
            const localVarAxiosArgs = await WorkoutPlanApiAxiosParamCreator(configuration).getAllWorkOutPlans(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get workout plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkOutPlan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOutPlan>> {
            const localVarAxiosArgs = await WorkoutPlanApiAxiosParamCreator(configuration).getWorkOutPlan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update workout plan
         * @param {number} id 
         * @param {WorkOutPlan} [workOutPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkOutPlan(id: number, workOutPlan?: WorkOutPlan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOutPlan>> {
            const localVarAxiosArgs = await WorkoutPlanApiAxiosParamCreator(configuration).updateWorkOutPlan(id, workOutPlan, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary confirm workout plan
         * @param {number} id 
         * @param {number} workoutId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkOutStatus(id: number, workoutId: number, status: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOutPlan>> {
            const localVarAxiosArgs = await WorkoutPlanApiAxiosParamCreator(configuration).updateWorkOutStatus(id, workoutId, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkoutPlanApi - factory interface
 * @export
 */
export const WorkoutPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary confirm workout plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWorkOutPlan(id: number, options?: any): AxiosPromise<WorkOutPlan> {
            return WorkoutPlanApiFp(configuration).confirmWorkOutPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new workout plan
         * @param {WorkOutPlan} [workOutPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkoutPlan(workOutPlan?: WorkOutPlan, options?: any): AxiosPromise<WorkOutPlan> {
            return WorkoutPlanApiFp(configuration).createWorkoutPlan(workOutPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete workout plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkOutPlan(id: number, options?: any): AxiosPromise<void> {
            return WorkoutPlanApiFp(configuration).deleteWorkOutPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workout plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserEditableWorkOutPlans(options?: any): AxiosPromise<Array<WorkOutPlan>> {
            return WorkoutPlanApiFp(configuration).getAllUserEditableWorkOutPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workout plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserWorkOutPlans(options?: any): AxiosPromise<Array<WorkOutPlan>> {
            return WorkoutPlanApiFp(configuration).getAllUserWorkOutPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workout plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkOutPlans(options?: any): AxiosPromise<Array<WorkOutPlan>> {
            return WorkoutPlanApiFp(configuration).getAllWorkOutPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get workout plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkOutPlan(id: number, options?: any): AxiosPromise<WorkOutPlan> {
            return WorkoutPlanApiFp(configuration).getWorkOutPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update workout plan
         * @param {number} id 
         * @param {WorkOutPlan} [workOutPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkOutPlan(id: number, workOutPlan?: WorkOutPlan, options?: any): AxiosPromise<WorkOutPlan> {
            return WorkoutPlanApiFp(configuration).updateWorkOutPlan(id, workOutPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary confirm workout plan
         * @param {number} id 
         * @param {number} workoutId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkOutStatus(id: number, workoutId: number, status: string, options?: any): AxiosPromise<WorkOutPlan> {
            return WorkoutPlanApiFp(configuration).updateWorkOutStatus(id, workoutId, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkoutPlanApi - object-oriented interface
 * @export
 * @class WorkoutPlanApi
 * @extends {BaseAPI}
 */
export class WorkoutPlanApi extends BaseAPI {
    /**
     * 
     * @summary confirm workout plan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutPlanApi
     */
    public confirmWorkOutPlan(id: number, options?: any) {
        return WorkoutPlanApiFp(this.configuration).confirmWorkOutPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new workout plan
     * @param {WorkOutPlan} [workOutPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutPlanApi
     */
    public createWorkoutPlan(workOutPlan?: WorkOutPlan, options?: any) {
        return WorkoutPlanApiFp(this.configuration).createWorkoutPlan(workOutPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete workout plan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutPlanApi
     */
    public deleteWorkOutPlan(id: number, options?: any) {
        return WorkoutPlanApiFp(this.configuration).deleteWorkOutPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workout plans
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutPlanApi
     */
    public getAllUserEditableWorkOutPlans(options?: any) {
        return WorkoutPlanApiFp(this.configuration).getAllUserEditableWorkOutPlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workout plans
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutPlanApi
     */
    public getAllUserWorkOutPlans(options?: any) {
        return WorkoutPlanApiFp(this.configuration).getAllUserWorkOutPlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workout plans
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutPlanApi
     */
    public getAllWorkOutPlans(options?: any) {
        return WorkoutPlanApiFp(this.configuration).getAllWorkOutPlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get workout plan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutPlanApi
     */
    public getWorkOutPlan(id: number, options?: any) {
        return WorkoutPlanApiFp(this.configuration).getWorkOutPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update workout plan
     * @param {number} id 
     * @param {WorkOutPlan} [workOutPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutPlanApi
     */
    public updateWorkOutPlan(id: number, workOutPlan?: WorkOutPlan, options?: any) {
        return WorkoutPlanApiFp(this.configuration).updateWorkOutPlan(id, workOutPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary confirm workout plan
     * @param {number} id 
     * @param {number} workoutId 
     * @param {string} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutPlanApi
     */
    public updateWorkOutStatus(id: number, workoutId: number, status: string, options?: any) {
        return WorkoutPlanApiFp(this.configuration).updateWorkOutStatus(id, workoutId, status, options).then((request) => request(this.axios, this.basePath));
    }
}
