































































import {namespace} from "vuex-class";
import {Component, Vue} from "vue-property-decorator";
import {User} from "@/api";
import notificationService, {NotificationType} from "@/service/notification.service";

const Auth = namespace("Auth");

@Component
export default class Login extends Vue {
  private user: any = {username: "", password: ""};
  private loading = false;
  private message = '';

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Getter
  private currentUser!: User;

  @Auth.Action
  private auth!: () => Promise<any>;

  @Auth.Action
  private login!: (data: any) => Promise<any>;

  created() {
    if (this.isLoggedIn) {
      this.$router.push("/dashboard");
    }
  }

  handleLogin() {
    this.loading = true;
    this.message = '';
    if (this.user.username && this.user.password) {
      this.login(this.user).then(() => {
        this.auth().then(
            () => {
              this.$router.push("/dashboard");
            },
            (error) => {
              this.loading = false;
              this.message = error;
              notificationService.showNotification(NotificationType.error, error)
            }
        );
      }).catch(error => {
        this.loading = false;
        this.message = error;
        notificationService.showNotification(NotificationType.error, error)
      })
    }
  }
}
