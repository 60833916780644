













import eventBus from '@/service/eventbus.service';
// eslint-disable-next-line no-unused-vars
import {
  NOTIFICATION,
  NOTIFICATION_HIDE,
  NOTIFICATION_HIDE_ALL,
  NOTIFICATION_HIDE_ERRORS,
  NOTIFICATION_UPDATE,
  NotificationMessage,
  NotificationType
} from '@/service/notification.service';
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({})
export default class Notifications extends Vue {

  elevation = 15;

  notifications: NotificationMessage[] = [];

  showNotification(notification: NotificationMessage) {
    if (notification.options?.top) {
      this.notifications.unshift(notification);
    } else {
      this.notifications.push(notification);
    }

    if (notification.type === NotificationType.success || notification.type === NotificationType.info) {
      setTimeout(() => {
        this.hideNotification(notification);
      }, 5000);
    } else if (notification.options?.timeout) {
      setTimeout(() => {
        this.hideNotification(notification);
      }, notification.options?.timeout);
    }
  }

  hideNotification(notification: NotificationMessage) {
    const index = this.notifications.indexOf(notification);
    if (index > -1) {
      this.notifications.splice(index, 1);
    }
  }

  hideNotificationById(id: number) {
    const index = this.notifications.findIndex(n => n.id === id);
    if (index > -1) {
      this.notifications.splice(index, 1);
    }
  }

  hideAll(notificationType?: NotificationType) {
    if (notificationType) {
      this.notifications
          .filter(n => n.type === notificationType)
          .forEach(n => this.hideNotification(n));
      return;
    }
    this.notifications = [];
  }

  hideErrors() {
    this.hideAll(NotificationType.error);
  }

  updateNotification(notification: NotificationMessage) {
    if (!notification || !notification.id) return;

    const index = this.notifications.findIndex(n => n.id === notification.id);
    if (index < 0) return;

    if (notification.options?.top) {
      this.notifications.splice(index, 1);
      this.notifications.unshift(notification);
    } else {
      this.notifications.splice(index, 1, notification);
    }
  }

  mounted() {
    eventBus.$on(NOTIFICATION, this.showNotification);
    eventBus.$on(NOTIFICATION_HIDE, this.hideNotificationById);
    eventBus.$on(NOTIFICATION_HIDE_ALL, this.hideAll);
    eventBus.$on(NOTIFICATION_HIDE_ERRORS, this.hideErrors);
    eventBus.$on(NOTIFICATION_UPDATE, this.updateNotification);
  }

  beforeDestroy() {
    eventBus.$off(NOTIFICATION);
    eventBus.$off(NOTIFICATION_HIDE);
    eventBus.$off(NOTIFICATION_HIDE_ALL);
    eventBus.$off(NOTIFICATION_HIDE_ERRORS);
    eventBus.$off(NOTIFICATION_UPDATE);
  }
}
