import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import AuthService from '@/service/auth.service';
import AccountService from '@/service/account.service';
import {JWTToken, Login} from "@/api";
import {
    ACCESS_TOKEN_FAILED,
    ACCESS_TOKEN_KEY,
    ACCESS_TOKEN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    USER_KEY
} from "@/store/constants/users";

const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
const storedUser = sessionStorage.getItem(USER_KEY);

@Module({namespaced: true})
class User extends VuexModule {
    public status = storedUser ? {loggedIn: true} : {loggedIn: false};
    public user = storedUser ? JSON.parse(storedUser) : null;
    public accessToken = accessToken ? accessToken : null;

    @Mutation
    public accessTokenSuccess(token: JWTToken): void {
        sessionStorage.setItem(ACCESS_TOKEN_KEY, token.accessToken!);
    }

    @Mutation
    public loginSuccess(user: User): void {
        sessionStorage.setItem(USER_KEY, JSON.stringify(user));
        this.user = user;
        this.status.loggedIn = true;
    }

    @Mutation
    public loginFailure(): void {
        this.status.loggedIn = false;
        this.user = null;
    }

    @Mutation
    public logout(): void {
        this.status.loggedIn = false;
        this.user = null;
    }

    @Mutation
    public registerSuccess(): void {
        this.status.loggedIn = false;
    }

    @Mutation
    public registerFailure(): void {
        this.status.loggedIn = false;
    }

    @Action({rawError: true})
    login(data: Login): Promise<any> {
        return AuthService.authorize(data).then(
            token => {
                this.context.commit(ACCESS_TOKEN_SUCCESS, token.data);
                return Promise.resolve(token);
            },
            error => {
                this.context.commit(ACCESS_TOKEN_FAILED);
                const message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }

    @Action({rawError: true})
    auth(): Promise<any> {
        return AccountService.getAccount().then(
            account => {
                this.context.commit(LOGIN_SUCCESS, account.data);
                return Promise.resolve(account);
            },
            error => {
                this.context.commit(LOGIN_FAILURE);
                const message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            });
    }

    @Action
    signOut(): void {
        AuthService.logout();
        this.context.commit('logout');
    }

    // @Action({ rawError: true })
    // register(data: any): Promise<any> {
    //     return AuthService.register(data.username, data.email, data.password).then(
    //         response => {
    //             this.context.commit('registerSuccess');
    //             return Promise.resolve(response.data);
    //         },
    //         error => {
    //             this.context.commit('registerFailure');
    //             const message =
    //                 (error.response && error.response.data && error.response.data.message) ||
    //                 error.message ||
    //                 error.toString();
    //             return Promise.reject(message);
    //         }
    //     );
    // }

    get isLoggedIn(): boolean {
        return this.status.loggedIn;
    }

    get currentUser(): User {
        return this.user;
    }
}

export default User;
