/* tslint:disable */
/* eslint-disable */
/**
 * aquaway-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GroupPlan } from '../model';
/**
 * GroupPlanApi - axios parameter creator
 * @export
 */
export const GroupPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new group plan
         * @param {GroupPlan} [groupPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupPlan: async (groupPlan?: GroupPlan, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/group-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof groupPlan !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(groupPlan !== undefined ? groupPlan : {}) : (groupPlan || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete group plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupPlan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteGroupPlan.');
            }
            const localVarPath = `/group-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get group plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGroupPlans: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/group-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get group plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupPlan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getGroupPlan.');
            }
            const localVarPath = `/group-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update group plan
         * @param {number} id 
         * @param {GroupPlan} [groupPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupPlan: async (id: number, groupPlan?: GroupPlan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateGroupPlan.');
            }
            const localVarPath = `/group-plan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof groupPlan !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(groupPlan !== undefined ? groupPlan : {}) : (groupPlan || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupPlanApi - functional programming interface
 * @export
 */
export const GroupPlanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new group plan
         * @param {GroupPlan} [groupPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroupPlan(groupPlan?: GroupPlan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupPlan>> {
            const localVarAxiosArgs = await GroupPlanApiAxiosParamCreator(configuration).createGroupPlan(groupPlan, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete group plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroupPlan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GroupPlanApiAxiosParamCreator(configuration).deleteGroupPlan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get group plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllGroupPlans(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupPlan>>> {
            const localVarAxiosArgs = await GroupPlanApiAxiosParamCreator(configuration).getAllGroupPlans(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get group plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupPlan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupPlan>> {
            const localVarAxiosArgs = await GroupPlanApiAxiosParamCreator(configuration).getGroupPlan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update group plan
         * @param {number} id 
         * @param {GroupPlan} [groupPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroupPlan(id: number, groupPlan?: GroupPlan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupPlan>> {
            const localVarAxiosArgs = await GroupPlanApiAxiosParamCreator(configuration).updateGroupPlan(id, groupPlan, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GroupPlanApi - factory interface
 * @export
 */
export const GroupPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create new group plan
         * @param {GroupPlan} [groupPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupPlan(groupPlan?: GroupPlan, options?: any): AxiosPromise<GroupPlan> {
            return GroupPlanApiFp(configuration).createGroupPlan(groupPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete group plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupPlan(id: number, options?: any): AxiosPromise<void> {
            return GroupPlanApiFp(configuration).deleteGroupPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get group plans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGroupPlans(options?: any): AxiosPromise<Array<GroupPlan>> {
            return GroupPlanApiFp(configuration).getAllGroupPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get group plan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupPlan(id: number, options?: any): AxiosPromise<GroupPlan> {
            return GroupPlanApiFp(configuration).getGroupPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update group plan
         * @param {number} id 
         * @param {GroupPlan} [groupPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupPlan(id: number, groupPlan?: GroupPlan, options?: any): AxiosPromise<GroupPlan> {
            return GroupPlanApiFp(configuration).updateGroupPlan(id, groupPlan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupPlanApi - object-oriented interface
 * @export
 * @class GroupPlanApi
 * @extends {BaseAPI}
 */
export class GroupPlanApi extends BaseAPI {
    /**
     * 
     * @summary Create new group plan
     * @param {GroupPlan} [groupPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupPlanApi
     */
    public createGroupPlan(groupPlan?: GroupPlan, options?: any) {
        return GroupPlanApiFp(this.configuration).createGroupPlan(groupPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete group plan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupPlanApi
     */
    public deleteGroupPlan(id: number, options?: any) {
        return GroupPlanApiFp(this.configuration).deleteGroupPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get group plans
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupPlanApi
     */
    public getAllGroupPlans(options?: any) {
        return GroupPlanApiFp(this.configuration).getAllGroupPlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get group plan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupPlanApi
     */
    public getGroupPlan(id: number, options?: any) {
        return GroupPlanApiFp(this.configuration).getGroupPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update group plan
     * @param {number} id 
     * @param {GroupPlan} [groupPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupPlanApi
     */
    public updateGroupPlan(id: number, groupPlan?: GroupPlan, options?: any) {
        return GroupPlanApiFp(this.configuration).updateGroupPlan(id, groupPlan, options).then((request) => request(this.axios, this.basePath));
    }
}
