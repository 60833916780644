import {RoleSettings} from "@/model/role-settings";

export enum Roles{
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_USER = 'ROLE_USER',
    ROLE_TRAINER = 'ROLE_TRAINER',
    ROLE_BOOKKEEPER = 'ROLE_BOOKKEEPER',
    ROLE_JURIST = 'ROLE_JURIST',
}

export const defaultRoleSettings: RoleSettings[] = [
    {
        id: 1,
        icon: 'mdi-cog-outline',
        color: 'light-green',
        authority: Roles.ROLE_ADMIN,
    },
    {
        id: 2,
        icon: 'mdi-account-outline',
        color: 'lime',
        authority: Roles.ROLE_USER,
    },
    {
        id: 3,
        icon: 'mdi-human-handsup',
        color: 'light-blue',
        authority: Roles.ROLE_TRAINER,
    },
    {
        id: 4,
        icon: 'mdi-book-open-outline',
        color: 'cyan',
        authority: Roles.ROLE_BOOKKEEPER,
    },
    {
        id: 5,
        icon: 'mdi-gavel',
        color: 'deep-orange',
        authority: Roles.ROLE_JURIST,
    }
]
