import {Configuration} from '@/api/configuration';
import {AuthenticateApi} from '@/api/service/authenticate-api';
import globalAxios, {AxiosInstance} from 'axios';
import store from '@/store';
import {UsersApi} from "@/api/service/users-api";
import {AccountApi} from "@/api/service/account-api";
import {ACCESS_TOKEN_KEY} from "@/store/constants/users";
import {RegistrationApi} from "@/api/service/registration-api";
import {LocationApi} from "@/api/service/location-api";
import {GroupApi} from "@/api/service/group-api";
import {GroupPlanApi} from "@/api/service/group-plan-api";
import {WorkoutPlanApi} from "@/api/service/workout-plan-api";
import {MailApi} from "@/api/service/mail-api";

class ApiInstance {

    axiosInstance: AxiosInstance;
    authenticateApi: AuthenticateApi;
    accountApi: AccountApi;
    usersApi: UsersApi;
    registrationApi: RegistrationApi;
    locationApi: LocationApi;
    groupApi: GroupApi;
    groupPlanApi: GroupPlanApi;
    workOutPlanApi: WorkoutPlanApi;
    mailApi: MailApi;

    constructor() {
        this.axiosInstance = globalAxios;

        this.axiosInstance.interceptors.request.use(requestConfig => {
            requestConfig.headers.authorization = `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_KEY)}`
            return requestConfig
        }, error => {
            return Promise.reject(error)
        });

        this.axiosInstance.interceptors.response.use((response) => response, (error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                store.commit('logout');
            }
            throw error
        });

        let baseURL

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            baseURL = '/api'
        } else {
             baseURL = 'https://aquaway.herokuapp.com/api'
            // baseURL = 'https://aquaway-test.herokuapp.com/api'
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.authenticateApi = new AuthenticateApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.usersApi = new UsersApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.accountApi = new AccountApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.registrationApi = new RegistrationApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.locationApi = new LocationApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.groupApi = new GroupApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.groupPlanApi = new GroupPlanApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.workOutPlanApi = new WorkoutPlanApi(new Configuration(), baseURL, axiosInstance);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.mailApi = new MailApi(new Configuration(), baseURL, axiosInstance);
    }
}

export const {axiosInstance, authenticateApi, usersApi, accountApi, registrationApi,locationApi,groupApi,groupPlanApi,workOutPlanApi,mailApi} = new ApiInstance();
