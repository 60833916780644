import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

const messages = {
    ru: {
        login: {
            title: 'Вход в Aquaway',
            username: 'Имя пользователя',
            password: 'Пароль',
            errors: {
                invalidLogin: 'Не правильное имя пользователя или пароль'
            }
        },
        navbar: {
            dashboard: 'Главное меню',
            profile: 'Профиль'
        },
        profile: {
            title: 'Мой профиль',
            main: 'Мои данные',
            fullName: 'Полное имя:',
            login: 'Имя пользователя:',
            roles: 'Доступные роли:'
        },
        cards: {
            dashboard: {
                title: 'Пользовательская панель',
                subtitle: 'Пользовательская панель обеспечивает управлеине и просмотр повседневных событий.'
            },
            location: {
                title: 'Бассейны',
                subtitle: 'Управление бассейнами или местами проведения тренеровок.',
                header: {
                    id: '#',
                    name: 'Название',
                    address: 'Адрес',
                    url: 'Ссылка',
                    coordinates: 'Координаты'
                }
            },
            registration: {
                title: 'Регистрации',
                subtitle: 'Управление регистрациями. Просмотр поступивших регитраций а также их статусы.',
                header: {
                    active: 'Активная',
                    clientGroup: 'Группа',
                    clientMailSent: 'Письмо с приглашением отправлено',
                    clientName: 'Данные ребёнка',
                    clientPersonalCode: 'IK ребенка',
                    comment: 'Откуда о нас узнали',
                    createdDate: 'Время регистрации',
                    address: 'Адрес проживания',
                    email: 'e-mail',
                    id: '#',
                    location: 'Бассейн',
                    parentName: 'Имя родителя',
                    parentPersonalCode: 'IK родителя',
                    phone: 'Телефон',
                    readTerms: 'С условиями согласен',
                    status: 'Статус',
                    swimSkills: 'Умеет плавать',
                }
            },
            group: {
                title: 'Группы',
                subtitle: 'Управление группами . Создание новых групп, редакирование групп.',
                header: {
                    id: '#',
                    name: 'Группа',
                    description: 'Описание',
                    location: 'Бассейн',
                    active: 'Активная',
                    code: 'Код группы',
                    trainer: 'Тренер'
                },
                search: 'Поиск группы'
            },
            workOutPlan: {
                title: 'Тренировки',
                subtitle: 'Управление тренировками. Создание списков, редакирование списков.',
                header: {
                    id: '#',
                    name: 'Группа',
                    location: 'Бассейн',
                    active: 'Активная',
                    code: 'Код группы',
                    trainer: 'Тренер',
                    date: 'Дата',
                    status: {
                        title: 'Статус',
                        CONFIRMED: 'Подтвержден',
                        NOT_CONFIRMED: 'Не подтвержден'
                    }
                },
                search: 'Поиск группы'
            },
            groupPlan: {
                title: 'Расписание групп',
                subtitle: 'Управление расписнием групп. Создание расписаний, редакирование расписаний.',
                header: {
                    id: '#',
                    description: 'Описание',
                    active: 'Активная',
                    groupName: 'Группа',
                    maxCount: 'Мест в группе'
                },
                items: 'Расписание',
                price: 'Оплата'
            },
            workOutPlanSummary: {
                title: 'Отчет по группам',
                subtitle: 'Отчет по посещениям учениками тренировок. Отчет составляется на основе данных полученых от тренеров.',
                header: {
                    id: '#',
                    description: 'Описание',
                    active: 'Активная',
                    groupName: 'Группа',
                    maxCount: 'Мест в группе'
                },
                items: 'Расписание',
                price: 'Оплата'
            },
            trainerBoard: {
                title: 'Кабинет тренера',
                subtitle: 'Управление отчетностью по группам. Создание и редакирование отчетов по группам.',
                cards: {
                    workOutPlanAdd: {
                        title: 'Создать',
                        subtitle: 'Тренировки',
                        registerWorkOut: 'Регистрация тренировки',
                        selectGroup: 'Выбрать группу'
                    },
                    workOutPlanEdit: {
                        title: 'Редактировть',
                        subtitle: 'Тренировки'
                    },
                    workOutPlanList: {
                        title: 'Список',
                        subtitle: 'Тренировки'
                    },
                    groups: {
                        title: 'Мои группы',
                        subtitle: 'Группы'
                    },
                    calendar: {
                        title: 'Мой календарь',
                        subtitle: 'Календарь'
                    }
                }
            }
        },
        actions: {
            logout: 'Выход',
            open: 'Открыть'
        },
        roles: {
            ROLE_ADMIN: 'Администратор',
            ROLE_USER: 'Пользователь',
            ROLE_TRAINER: 'Тренер',
            ROLE_JURIST: 'Юрист',
            ROLE_BOOKKEEPER: 'Бухгалтер'
        },
        labels: {
            yes: 'Да',
            no: 'Нет',
            syncing: 'Синхорнизация данных',
        },
        registrationStatus: {
            NOT_PROCESSED: 'Не обработана',
            PROCESSED: 'Обработана',
            NOT_VALID: 'Ошибка в данных, обновить'
        },
        button: {
            back: 'Назад',
            login: 'Вход',
            add: 'Добавить',
            confirm: 'Подтвердить',
            yes: 'Да',
            no: 'Нет',
            delete: 'Удалить',
            cancel: 'Отменить',
            send: "Отправить",
            close: "Закрыть",
            update: "Обновить",
            updateData: "Обновить данные"
        },
        workout: {
            status: {PRESENT: 'На месте', NOT_PRESENT: 'Отсутствует'}
        },
        workOutPlan: {
            status: {
                NOT_CONFIRMED: 'Не подтвержден',
                CONFIRMED: 'Подтверждён'
            }
        },
        clients: {title: 'Ученики'},
        client: {
            age: 'Возраст'
        },
        dialog: {
            workOutPlanConfirmDialog: {
                question: 'Вы уверены, что подтверждаете список?',
                message: 'После подтверждения списка с учениками, редактирование списка не будет больше возможным.'
            },
            workOutPlanDeleteDialog: {
                question: 'Вы уверены, что хотите удалить список?',
                message: 'После удаления списка уго нельзя будет восстановить'
            }
        },
        skills: {
            NO_SKILL: 'Нет',
            ONE_YEAR: '1 год',
            TWO_YEARS: '2 года',
            MORE_THAN_TWO_YEAR: 'Больше 2 лет'
        },
        messages: {
            success: {
                update: 'Обновление удалось'
            }
        }
    }
};


export default new VueI18n({
    locale: 'ru',
    messages
})
