import {authenticateApi, usersApi} from '@/api/api.instance'
import {JWTToken, Login} from "@/api";
import {AxiosPromise} from "axios";
import {ACCESS_TOKEN_KEY, USER_KEY} from "@/store/constants/users";

class AuthService {


    authorize(loginForm: Login): AxiosPromise<JWTToken> {
        return authenticateApi.authorize(loginForm)
    }

    // login(loginForm: string): AxiosPromise<JWTToken> {
    //     //return usersApi.getUser()authorize(loginForm)
    // }

    logout() {
        sessionStorage.removeItem(ACCESS_TOKEN_KEY);
        sessionStorage.removeItem(USER_KEY);
    }
}

export default new AuthService();
