import Vue from 'vue';
import VueRouter, {RouteConfig} from "vue-router";
import Login from '@/views/Login.vue';
import Register from '@/components/Register.vue';
import Dashboard from "@/views/Dashboard.vue";
import Calender from "@/components/Calendar.vue";
import {USER_KEY} from "@/store/constants/users";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard
    },

    {
        path: '/login',
        component: Login
    },
    {
        path: '/register',
        component: Register
    },
    {
        path: '/dashboard',
        component: Dashboard
    },
    {
        path: '/calender',
        component: Calender
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/components/Profile.vue')
    },
    {
        path: '/location',
        name: 'location',
        component: () => import('@/views/Location.vue')
    },
    {
        path: '/registration',
        name: 'registration',
        component: () => import('@/views/Registration.vue')
    },
    {
        path: '/group',
        name: 'group',
        component: () => import('@/views/Group.vue')
    },
    {
        path: '/group-plan',
        name: 'groupPlan',
        component: () => import('@/views/GroupPlan.vue')
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('@/components/BoardUser.vue')
    },
    {
        path: '/trainer',
        name: 'trainer',
        component: () => import('@/views/Trainer.vue'),
    },
    {
        path: '/trainer/workout-plan/add',
        name: 'workoutPlanAdd',
        component: () => import('@/components/WorkoutPlanForm.vue'),
    },
    {
        path: '/trainer/workout-plan/:id/edit',
        name: 'workoutPlanEdit',
        component: () => import('@/components/WorkoutPlanEditForm.vue'),
    },
    {
        path: '/trainer/workout-plan/edit-list',
        name: 'workoutPlanEditTable',
        component: () => import('@/components/WorkoutPlanEditTable.vue'),
    },
    {
        path: '/trainer/workout-plan/list',
        name: 'workoutPlan',
        component: () => import('@/components/WorkoutPlanTable.vue'),
    },
    {
        path: '/workout/report',
        name: 'workoutReport',
        component: () => import('@/views/WorkOutReport.vue'),
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);

    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = sessionStorage.getItem(USER_KEY);

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router
