<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="pa-3 mx-auto"
                flat
                color="amber lighten-5"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">
                РОДИТЕЛЬ
              </div>
              <v-list-item-title class="headline mb-1">
                VLADIMIR DOMASHKIN
              </v-list-item-title>
              <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
            </v-list-item-content>


          </v-list-item>

          <v-card-actions>
            <v-btn
                rounded
                outlined

                color="amber"
            >
              Button
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="pa-3 mx-auto mb-3"
                outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">
                УЧЕНИК
              </div>
              <v-list-item-title class="headline mb-1">
                VLADIMIR DOMASHKIN
              </v-list-item-title>
              <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
            </v-list-item-content>


          </v-list-item>

          <v-card-actions>
            <v-btn
                outlined
                rounded
                text
            >
              Button
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="pa-3 mx-auto"
                outlined

        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">
                УЧЕНИК
              </div>
              <v-list-item-title class="headline mb-1">
                ALEXANDR DOMASHKIN
              </v-list-item-title>
              <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
            </v-list-item-content>


          </v-list-item>

          <v-card-actions>
            <v-btn
                outlined
                rounded
                text
            >
              Button
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
      <v-col cols="12" md="4">
        <v-card
            class="mx-auto"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">
                San Francisco
              </v-list-item-title>
              <v-list-item-subtitle>Mon, 12:30 PM, Mostly sunny</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            <v-row align="center">
              <v-col
                  class="display-3"
                  cols="6"
              >
                23&deg;C
              </v-col>
              <v-col cols="6">
                <v-img
                    src="https://cdn.vuetifyjs.com/images/cards/sun.png"
                    alt="Sunny image"
                    width="92"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-send</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>23 km/h</v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-cloud-download</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>48%</v-list-item-subtitle>
          </v-list-item>

          <v-slider
              v-model="time"
              :max="6"
              :tick-labels="labels"
              class="mx-4"
              ticks
          ></v-slider>

          <v-list class="transparent">
            <v-list-item
                v-for="item in forecast"
                :key="item.day"
            >
              <v-list-item-title>{{ item.day }}</v-list-item-title>

              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-subtitle class="text-right">
                {{ item.temp }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text>
              Full Report
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
   </v-container>

</template>

<script lang="js">
import Vue from 'vue'
import Login from "@/views/Login.vue";

export default Vue.extend({
  name: 'DashboardMain',
  components: {},
  data: () => ({
    ecosystem: [
      {
        text: 'vuetify-loader',
        href: 'https://github.com/vuetifyjs/vuetify-loader',
      },
      {
        text: 'github',
        href: 'https://github.com/vuetifyjs/vuetify',
      },
      {
        text: 'awesome-vuetify',
        href: 'https://github.com/vuetifyjs/awesome-vuetify',
      },
    ],
    importantLinks: [
      {
        text: 'Documentation',
        href: 'https://vuetifyjs.com',
      },
      {
        text: 'Chat',
        href: 'https://community.vuetifyjs.com',
      },
      {
        text: 'Made with Vuetify',
        href: 'https://madewithvuejs.com/vuetify',
      },
      {
        text: 'Twitter',
        href: 'https://twitter.com/vuetifyjs',
      },
      {
        text: 'Articles',
        href: 'https://medium.com/vuetify',
      },
    ],
    whatsNext: [
      {
        text: 'Explore components',
        href: 'https://vuetifyjs.com/components/api-explorer',
      },
      {
        text: 'Select a layout',
        href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
      },
      {
        text: 'Frequently Asked Questions',
        href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
      },
    ],
  }),
})
</script>
