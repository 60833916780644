




































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import DashboardMain from "@/components/DashboardMain.vue";
import {MenuCardItem} from "@/model/menu-card-item";
import {RoleSettings} from "@/model/role-settings";
import {defaultRoleSettings, Roles} from "@/constants/role.constants";

const Auth = namespace("Auth");
@Component({
  components: {DashboardMain}
})
export default class Dashboard extends Vue {
  menuItems: MenuCardItem[] = [
    {
      id: 1,
      title: this.$t('cards.dashboard.title') as string,
      subtitle: this.$t('cards.dashboard.subtitle') as string,
      dark: false,
      to: '/user',
      authority: Roles.ROLE_USER,
      actionTitle: this.$t('actions.open') as string,
      disabled: true
    },
    {
      id: 4,
      title: this.$t('cards.registration.title') as string,
      subtitle: this.$t('cards.registration.subtitle') as string,
      dark: false,
      to: '/registration',
      authority: Roles.ROLE_ADMIN,
      actionTitle: this.$t('actions.open') as string
    },
    {
      id: 2,
      title: this.$t('cards.location.title') as string,
      subtitle: this.$t('cards.location.subtitle') as string,
      dark: false,
      to: '/location',
      authority: Roles.ROLE_ADMIN,
      actionTitle: this.$t('actions.open') as string
    }, {
      id: 3,
      title: this.$t('cards.group.title') as string,
      subtitle: this.$t('cards.group.subtitle') as string,
      dark: false,
      to: '/group',
      authority: Roles.ROLE_ADMIN,
      actionTitle: this.$t('actions.open') as string
    }, {
      id: 5,
      title: this.$t('cards.groupPlan.title') as string,
      subtitle: this.$t('cards.groupPlan.subtitle') as string,
      dark: false,
      to: '/group-plan',
      authority: Roles.ROLE_ADMIN,
      actionTitle: this.$t('actions.open') as string
    }, {
      id: 7,
      title: this.$t('cards.workOutPlanSummary.title') as string,
      subtitle: this.$t('cards.workOutPlanSummary.subtitle') as string,
      dark: false,
      to: '/workout/report',
      authority: Roles.ROLE_BOOKKEEPER,
      actionTitle: this.$t('actions.open') as string
    },
    {
      id: 6,
      title: this.$t('cards.trainerBoard.title') as string,
      subtitle: this.$t('cards.trainerBoard.subtitle') as string,
      dark: false,
      to: '/trainer',
      authority: Roles.ROLE_TRAINER,
      actionTitle: this.$t('actions.open') as string
    },
  ]

  @Auth.State("user")
  private currentUser!: any;

  @Auth.Action
  private signOut!: () => void;

  get visibleMenuItems(): MenuCardItem[] {
    return this.menuItems.filter(i => !i.disabled);
  }

  roleSettings(role: string): RoleSettings {
    return defaultRoleSettings.find(r => r.authority === role)!
  }

  hasAuthority(authority: string): boolean {
    if (this.currentUser && this.currentUser.authorities) {
      return this.currentUser.authorities.includes(authority);
    }
    return false;
  }


  profile() {
    this.$router.push("/profile");
  }

  logOut() {
    this.signOut();
    this.$router.push("/login");
  }

}
