<template>
  <div>Register</div>
</template>

<script>
export default {
  name: "Register"
}
</script>

<style scoped>

</style>