












































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import Notifications from "@/components/notification/Notifications.vue";

const Auth = namespace("Auth");
@Component({
  components: {Notifications}
})
export default class App extends Vue {

  @Auth.State("user")
  private currentUser!: any;

  @Auth.Action
  private signOut!: () => void;

  dashboard() {
    this.$router.push("/dashboard");
  }

  profile() {
    this.$router.push("/profile");
  }

  calendar() {
    this.$router.push("/calendar");
  }

  logOut() {
    this.signOut();
    this.$router.push("/login");
  }

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  }
}
