/* tslint:disable */
/* eslint-disable */
/**
 * aquaway-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Mail } from '../model';
// @ts-ignore
import { WorkOutPlan } from '../model';
/**
 * MailApi - axios parameter creator
 * @export
 */
export const MailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new mail
         * @param {WorkOutPlan} [workOutPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMail: async (workOutPlan?: WorkOutPlan, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof workOutPlan !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workOutPlan !== undefined ? workOutPlan : {}) : (workOutPlan || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send mail
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling sendMail.');
            }
            const localVarPath = `/mail/send/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailApi - functional programming interface
 * @export
 */
export const MailApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new mail
         * @param {WorkOutPlan} [workOutPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMail(workOutPlan?: WorkOutPlan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mail>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).createMail(workOutPlan, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send mail
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MailApiAxiosParamCreator(configuration).sendMail(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MailApi - factory interface
 * @export
 */
export const MailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create new mail
         * @param {WorkOutPlan} [workOutPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMail(workOutPlan?: WorkOutPlan, options?: any): AxiosPromise<Mail> {
            return MailApiFp(configuration).createMail(workOutPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send mail
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail(id: number, options?: any): AxiosPromise<void> {
            return MailApiFp(configuration).sendMail(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailApi - object-oriented interface
 * @export
 * @class MailApi
 * @extends {BaseAPI}
 */
export class MailApi extends BaseAPI {
    /**
     * 
     * @summary Create new mail
     * @param {WorkOutPlan} [workOutPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public createMail(workOutPlan?: WorkOutPlan, options?: any) {
        return MailApiFp(this.configuration).createMail(workOutPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send mail
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public sendMail(id: number, options?: any) {
        return MailApiFp(this.configuration).sendMail(id, options).then((request) => request(this.axios, this.basePath));
    }
}
